import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Footer = () => {
    const { t } = useTranslation();

    const [formData, setFormData] = useState({
        email: ''
    });
    const [isLoading, setIsLoading] = useState(false); // State variable to track loading state

    const isFormValid = () => {
        // Add your form validation logic here
        // For simplicity, we'll check if any of the required fields are empty
        return (
            formData.email.trim() !== ''
        );
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!isFormValid()) {
                return;
            }
            // Set loading state to true before handling form submission
            setIsLoading(true);
            const response = await fetch('https://contactapi.dltlabz.io/api/user/subscribe', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            // console.log(response);
            if (response.ok) {
                // Function to trigger the toast
                toast.success("Congratulations! You have successfully subscribed to our services.", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000, // Auto close the toast after 3000ms (3 seconds)
                });
                // Simulate an asynchronous form submission with setTimeout
                setTimeout(() => {
                    // Handle form submission logic here (e.g., API request, etc.)

                    // After form submission, reset the form and loading state
                    setFormData({
                        email: '',
                    });
                    setIsLoading(false);
                }, 2000);
                // Handle successful form submission (e.g., show a success message)
                // console.log('Form submitted successfully!');
            } else {
                // Handle errors (e.g., show an error message)
                // console.error('Form submission failed.');
                toast.error("Already this Email is registered for newsletter", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000, // Auto close the toast after 3000ms (3 seconds)
                });
                // Handle form submission logic here (e.g., API request, etc.)

                // After form submission, reset the form and loading state
                setFormData({
                    email: '',
                });
                setIsLoading(false);
            }
        } catch (error) {
            // console.error('Error submitting form:', error);
            toast.error('Error submitting form', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000, // Auto close the toast after 3000ms (3 seconds)
            });
        }
    };

    return (
        <>
            <div className="footer-section">
                <div className="container">
                    <div className="footer-main">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="footer-logo">
                                    <img src="../assets/images/logos/footer.svg" alt="dltlabz logo" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <ul className="footer-lnkgroup">
                                    <li><NavLink className="nav-link footer-txt" to={'/about-us'}>{t('footer.About Us')}</NavLink></li>
                                    {/* <li><NavLink className="nav-link footer-txt" to={'/AboutUs'}>Services</NavLink></li> */}
                                    <li><NavLink className="nav-link footer-txt" to={'/start-a-project'}>{t('footer.Start Project')}</NavLink></li>
                                    <li><NavLink className="nav-link footer-txt" to={'/start-a-project'}>{t('footer.Contact Us')}</NavLink></li>
                                </ul>
                            </div>
                            <div className="col-md-3">
                                <ul className="footer-social">
                                    <li><NavLink className="nav-link social-i" to={'https://www.linkedin.com/company/dltlabz/'} target="_blank"><img className="social-im" src="../assets/images/linkedin.svg" alt="no-error" /></NavLink></li>
                                    <li><NavLink className="nav-link social-i" to={'https://www.facebook.com/dltlabz'} target="_blank"><img className="social-im" src="../assets/images/facebook.svg" alt="no-error" /></NavLink></li>
                                    <li><NavLink className="nav-link social-i" to={'https://twitter.com/dltlabz'} target="_blank"><img className="social-im" src="../assets/images/x.png" alt="no-error" /></NavLink></li>
                                </ul>
                            </div>
                        </div>
                        <div className="footer-second">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="footer-left">
                                        <h6 className="contactus-txt">{t('footer.Contact Us')}:</h6>
                                        <p className="contacttext">{t('footer.Email')}: info@dltlabz.io</p>
                                        <p className="contacttext">{t('footer.Phone')}: +971 48347768</p>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="offices-section">
                                                    <img className="officesim" src="../assets/images/office-image.png" alt="dltlabz logo" />
                                                    <div>
                                                        <h6 className="offices-txt">{t('footer.Dubai Office')}</h6>
                                                        <p className="office-subtxt">{t('footer.Tamani Arts 1519, Business Bay, Dubai, United Arab Emirates')}.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="offices-section">
                                                    <img className="officesim" src="../assets/images/office-image.png" alt="dltlabz logo" />
                                                    <div>
                                                        <h6 className="offices-txt">{t('footer.Bangkok Office')}</h6>
                                                        <p className="office-subtxt">{t('footer.2922/277, 24th Floor, Charn Issara Tower 2, New Phetchburi Road, Bangkapi, Huaykwang, Bangkok 10310.')}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="footer-right">
                                        <form className="row g-3" onSubmit={handleSubmit}>
                                            <div className="col-md-6">
                                                <input
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    type="email"
                                                    className="form-control footer-inp"
                                                    id="inputEmail"
                                                    placeholder={t('footer.Email')}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <button type="submit" disabled={isLoading || !isFormValid()} className={isFormValid() ? 'enabled-button' : 'disabled-button'}>{isLoading ? t('footer.Submitting...') : t('footer.Subscribe to news')}</button>
                                                <ToastContainer />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className="copyright-txt">© {t('footer.2023 DLT Labz. All rights reserved')}<NavLink className="copyright-txt2" to={'/AboutUs'}>{t('footer.Privacy Policy')}</NavLink></p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer;