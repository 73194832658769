import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { useTranslation } from "react-i18next";

const DevOpsSolutions = () => {
    const { t } = useTranslation();
    const [swiperRef, setSwiperRef] = useState(null);
    const location = useLocation();

    useEffect(() => {
        const hash = location?.hash;
        if (hash) {
            const section = document.querySelector(hash);
            section?.scrollIntoView({ behavior: "smooth", block: "start" });
        } else {
            window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        }
    });
    return (
        <>
            <div className="mainsection">
                <img className="mainbgcircle" src="../assets/images/mainbgcircle.svg" alt="" />
                {/*  */}
                <div className="maintxt-section">
                    <h1 className="maintxt1">{t('DevOpsSolutions.Welcome to the')}</h1>
                    <h1 className="maintxt1 maintext2">{t('DevOpsSolutions.DevOps Solutions')}</h1>
                    <p className="maintxtsub">
                        {t('DevOpsSolutions.DLT LABZ, which uses data programs and authentication')}
                    </p>
                    <ul className="mainse-bts">
                        <li>
                            <NavLink to={'/Startaproject'}>
                                <div className="mainbts-bg">
                                    <button className="mainbts mainbts-metaverse" to={'/'}>{t('DevOpsSolutions.Start a Project')}</button>
                                </div>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="secondsection-forall secondsection-business">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="secondsectionforall-left secondsectionforall-devops">
                                <h2 className="section-mainhead">
                                    {t('DevOpsSolutions.Initiate your DevOps Journey with us')}
                                </h2>
                                <p className="section-maintxt">
                                    {t('DevOpsSolutions.We deliver engineering teams with')}
                                </p>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <img className="section-mainim" src="../assets/images/Group 1171275788.png" alt="dlt labz services devops" />
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="devops-section3main">
                <img className="devopscircle" src="../assets/images/mainbgcircle.svg" alt="" />
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <div className="devops-se3main devops-se3main3">
                                <h6 className="devopsse3main-head">Need Name</h6>
                                <p className="devopsse3main-txt">
                                    Our DevOps solutions plug the gaps which occur between software development, quality assurance, and IT procedures, empowering you to rapidly produce software products and services.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="devops-se3main devops-se3main4">
                                <h6 className="devopsse3main-head">Need Name</h6>
                                <p className="devopsse3main-txt">
                                    DLT LABZ trusts in the active mantra, “Persons over Practice over Tools.”
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <div className="devops-se3main devops-se3main2 devops-se3main3">
                                <h6 className="devopsse3main-head">Need Name</h6>
                                <p className="devopsse3main-txt">
                                    DevOps is more about assembling your teams rather than technology. Learn how progressive DevOps features from DLT LABZ can develop your product code and architecture.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="devops-se3main devops-se3main2 devops-se3main5">
                                <h6 className="devopsse3main-head">Need Name</h6>
                                <p className="devopsse3main-txt">
                                    Improving DevOps creates better synchronization, responsibility, and results.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="visionandmission-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="vision-section">
                                <h6 className="vision-head">{t('DevOpsSolutions.Our Vision')}</h6>
                                <img className="vision-imin" src="../assets/images/Vision.svg" alt="error" />
                                <p className="vision-txt">
                                    {t('DevOpsSolutions.We aspire to be leaders in business solutions')}
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="vision-section mission-section">
                                <h6 className="vision-head">{t('DevOpsSolutions.Our Mission')}</h6>
                                <img className="vision-imin Mission-imin" src="../assets/images/Mission.svg" alt="error" />
                                <p className="vision-txt">
                                    {t('DevOpsSolutions.Give modern and future-looking')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="projects-section projects-section2">
                {/* <img className="projectssection-bg" src="../assets/images/projects-section-bg.svg" alt="" /> */}
                <div className="container">
                    <div className="projects-main">
                        <h6 className="projects-txt">{t('information-technology.Some treasures')}</h6>
                        <div className="row">
                            <div className="col-md-4">
                                <NavLink to={"https://smashit.live"} target="_blank" style={{ cursor: 'pointer', textDecoration: 'none', }}>
                                    <h6 className="project-name">Smashit Live</h6>
                                    <img className="projectim" src="../assets/images/smashitlive.png" alt="error" />
                                </NavLink>
                            </div>
                            <div className="col-md-4">
                                <NavLink to={"https://wavpe.com"} target="_blank" style={{ cursor: 'pointer', textDecoration: 'none', }}>
                                    <h6 className="project-name">Wavpe</h6>
                                    <img className="projectim" src="../assets/images/wavpe.png" alt="error" />
                                </NavLink>
                            </div>
                            <div className="col-md-4">
                                <NavLink to={"https://www.coinovy.com"} target="_blank" style={{ cursor: 'pointer', textDecoration: 'none', }}>
                                    <h6 className="project-name">Coinovy</h6>
                                    <img className="projectim" src="../assets/images/coinovy.png" alt="error" />
                                    {/* <div className="row">
                                        <div className="col-12">
                                            <h6 className="project-name">Coinovy</h6>
                                        </div>
                                    </div> */}
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section6-faq">
                <div className="container">
                    <h2 className="section6-head">{t('DevOpsSolutions.FAQ')}</h2>
                    <h2 className="section6-head">{t('DevOpsSolutions.Find out what matters to you!')}Find out what matters to you!</h2>
                    <div>
                        <div className="wrapper">
                            <div className="accordion accordion-flush" id="myAccordion">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne"> <span>01 </span>{t('DevOpsSolutions.What is DevOps?')}</button>
                                    </h2>
                                    <div id="flush-collapseOne" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">{t('DevOpsSolutions.DevOps is a set of practices that combines')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo"> <span>02 </span>{t('DevOpsSolutions.Why is DevOps important for businesses?')}</button>
                                    </h2>
                                    <div id="flush-collapseTwo" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">{t('DevOpsSolutions.DevOps promotes faster and more')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree"> <span>03 </span>{t('DevOpsSolutions.Can you help with setting up automated')}</button>
                                    </h2>
                                    <div id="flush-collapseThree" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">{t('DevOpsSolutions.Yes, we specialize in creating automated testing')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour"> <span>04 </span>{t('DevOpsSolutions.How do you ensure security in DevOps practices?')}</button>
                                    </h2>
                                    <div id="flush-collapseFour" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">{t('DevOpsSolutions.Security is a key consideration in our DevOps')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive"> <span>05 </span>{t('DevOpsSolutions.How can I get started with your DevOps services?')} </button>
                                    </h2>
                                    <div id="flush-collapseFive" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">{t('DevOpsSolutions.To get started, simply reach out to')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default DevOpsSolutions;